<script setup>
import {useBaseStore} from "~/stores/base";
import {useCartStore} from "~/stores/cart";
import {BNTO_STATUS, PATH_UNLOCK, SKU_STATUS} from "~/utils/constants";

const router = useRouter()

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isLogin = computed(() => useBaseStore().getterIsLogin)
const userInfo = computed(() => useBaseStore().getterUserInfo)
const topInfo = computed(() => useCartStore().getterTopInfo)
const cartItemList = computed(() => useCartStore().getterCartItemList)

/** methods **/
const handleCloseDrawerCart = () => {
  // 获取当前路径，但去掉 hash 部分
  router.replace({
    path: router.currentRoute.value.path,
    query: router.currentRoute.value.query, // 保留查询参数
    hash: '' // 移除 hash
  });
}
</script>

<template>
  <div class="module-cart-header-mobile" v-if="isMobile">
    <div class="close" @click="handleCloseDrawerCart">
      <img src="@/assets/img/icon-close-black.svg" alt="close">
    </div>

    <div class="header-top">
      <!-- 已登录 -->
      <template v-if="isLogin">
        <!-- 是会员 -->
        <template v-if="userInfo.isMember">
          <div class="number" v-if="topInfo.status === BNTO_STATUS.OPEN || topInfo.status === BNTO_STATUS.FULL">
            {{ cartItemList.length }}
            <span class="upper-limit">
              / {{ userInfo.bntoUpperLimit }}
            </span>
          </div>

          <div class="cube" v-else-if="
            topInfo.status === BNTO_STATUS.REQUESTED ||
            topInfo.status === BNTO_STATUS.INCOMING ||
            topInfo.status === BNTO_STATUS.PARTIALLY_INCOMING ||
            topInfo.status === BNTO_STATUS.DELIVERED ||
            topInfo.status === BNTO_STATUS.PARTIALLY_DELIVERED
          ">
            <img src="@/assets/img/icon-cube.svg" alt="cube">
          </div>

          <div class="lock" v-else-if="
            topInfo.status === BNTO_STATUS.RETURN_IN_TRANSIT ||
            topInfo.status === BNTO_STATUS.RETURNED ||
            topInfo.status === BNTO_STATUS.UNRESOLVED ||
            topInfo.status === BNTO_STATUS.PAUSED ||
            topInfo.status === BNTO_STATUS.CANCELLED
          ">
            <img src="@/assets/img/icon-locked.svg" alt="locked">
          </div>
        </template>
        <!-- 非会员 -->
        <div class="lock" v-else>
          <img src="@/assets/img/icon-locked.svg" alt="locked">
        </div>
      </template>
      <!-- 未登录 -->
      <div class="lock" v-else>
        <img src="@/assets/img/icon-locked.svg" alt="locked">
      </div>

      <!-- 已登录 -->
      <template v-if="isLogin">
        <!-- 是会员 -->
        <div class="status" v-if="userInfo.isMember">
          Status:
          <span
            class="label"
            :class="{
              alert: topInfo.status === BNTO_STATUS.UNRESOLVED,
              bordered: topInfo.status === BNTO_STATUS.PAUSED || topInfo.status === BNTO_STATUS.CANCELLED
            }"
          >
            {{ topInfo.status }}
          </span>
          <span class="purchased" v-if="topInfo.purchasedNum">{{topInfo.purchasedNum}} Purchased</span>
        </div>
        <!-- 非会员 -->
        <div class="status" v-else>
          Status: <span class="label bordered">Not Subscribed</span>
        </div>
      </template>
      <!-- 未登录 -->
      <div class="status" v-else>
        Status: <span class="label bordered">Guest</span>
      </div>
    </div>

    <!-- situation 11 取消-->
    <div class="header-bottom" :style="{display: isLogin && userInfo.isMember && topInfo.status === BNTO_STATUS.CANCELLED ? 'none' : 'block'}">
      <!-- 已登录 -->
      <template v-if="isLogin">
        <!-- 是会员 -->
        <template v-if="userInfo.isMember">
          <template v-if="topInfo.status === BNTO_STATUS.OPEN || topInfo.status === BNTO_STATUS.FULL">
            <!-- situation 1 空购物车-->
            <span v-if="cartItemList.length === 0">
              Your BNTO is ready to fill! You can rent up to {{ userInfo.bntoUpperLimit }} items this month.
            </span>

            <!-- situation 2 非空购物车 -->
            <template v-else-if="cartItemList.length > 0 && cartItemList.length < userInfo.bntoUpperLimit">
              <!-- situation 3 存在缺货 -->
              <span class="alert" v-if="cartItemList.find(item => item.skuStatus === SKU_STATUS.OUT_OF_STOCK)">
                Sorry, one or more items have been rented out. Please choose another style.
              </span>

              <span v-else>Add more styles to your BNTO.</span>
            </template>

            <!-- situation 4 购物车已满 -->
            <template v-else-if="cartItemList.length === userInfo.bntoUpperLimit">
              <!-- situation 3 存在缺货 -->
              <span class="alert" v-if="cartItemList.find(item => item.skuStatus === SKU_STATUS.OUT_OF_STOCK)">
                Sorry, one or more items have been rented out. Please choose another style.
              </span>

              <span v-else>Your BNTO is full; Please click continue to confirm shipment.</span>
            </template>
          </template>

          <!-- situation 5 订单已提交-->
          <span v-else-if="topInfo.status === BNTO_STATUS.REQUESTED">
            Delivery tracking: Please check back soon for updates.
          </span>

          <!-- situation 6 已发货或分包发货-->
          <div
            class="space-between"
            v-else-if="topInfo.status === BNTO_STATUS.INCOMING || topInfo.status === BNTO_STATUS.PARTIALLY_INCOMING"
          >
            <span>Your BNTO is on its way. Please allow 24-48 hours for shipping updates.</span>
            <nuxt-link :to="topInfo.deliveryTrackingUrl" class="btn-secondary">track bnto</nuxt-link>
          </div>

          <!-- situation 7 已送达或分包送达-->
          <span
            v-else-if="topInfo.status === BNTO_STATUS.DELIVERED || topInfo.status === BNTO_STATUS.PARTIALLY_DELIVERED">
            We hope you are enjoying your BNTO! You will need to return or purchase your current items to unlock your next BNTO box. When returning, please ensure all 6 items are included except for any you have purchased. Any rented items not included will be considered purchased and charged to your account. Once you drop off your return, please allow additional time for the return to sync to BNTO and for your account to unlock.
          </span>

          <!-- situation 8 退回中 已退还-->
          <div
            class="space-between"
            v-else-if="topInfo.status === BNTO_STATUS.RETURN_IN_TRANSIT || topInfo.status === BNTO_STATUS.RETURNED"
          >
            <span>You need to wait until {{userInfo.nextBillingDate}} to place your next BNTO or you can unlock early</span>
            <nuxt-link :to="PATH_UNLOCK" class="btn-secondary">unlock early</nuxt-link>
          </div>

          <!-- situation 9 退货异常-->
          <span v-else-if="topInfo.status === BNTO_STATUS.UNRESOLVED">
            We received your return, but there was one or more issue.
          </span>

          <!-- situation 10 暂停-->
          <span v-else-if="topInfo.status === BNTO_STATUS.PAUSED">
            Next Billing Date <span class="bold">{{ userInfo.nextBillingDate }}</span>
          </span>
        </template>

        <!-- situation 13 非会员/未订阅 -->
        <span v-else>
          Subscribe to add items to your BNTO.
        </span>
      </template>

      <!-- situation 12 游客未登录 -->
      <span v-else>
        Join or Login to add items to your BNTO!
      </span>
    </div>
  </div>
  <div class="module-cart-header-desktop" v-else>
    <div class="close" @click="handleCloseDrawerCart">
      <img src="@/assets/img/icon-close-black.svg" alt="close">
    </div>

    <div class="header-top">
      <!-- 已登录 -->
      <template v-if="isLogin">
        <!-- 是会员 -->
        <template v-if="userInfo.isMember">
          <div class="number" v-if="topInfo.status === BNTO_STATUS.OPEN || topInfo.status === BNTO_STATUS.FULL">
            {{ cartItemList.length }}
            <span class="upper-limit">
              / {{ userInfo.bntoUpperLimit }}
            </span>
          </div>

          <div class="cube" v-else-if="
            topInfo.status === BNTO_STATUS.REQUESTED ||
            topInfo.status === BNTO_STATUS.INCOMING ||
            topInfo.status === BNTO_STATUS.PARTIALLY_INCOMING ||
            topInfo.status === BNTO_STATUS.DELIVERED ||
            topInfo.status === BNTO_STATUS.PARTIALLY_DELIVERED
          ">
            <img src="@/assets/img/icon-cube.svg" alt="cube">
          </div>

          <div class="lock" v-else-if="
            topInfo.status === BNTO_STATUS.RETURN_IN_TRANSIT ||
            topInfo.status === BNTO_STATUS.RETURNED ||
            topInfo.status === BNTO_STATUS.UNRESOLVED ||
            topInfo.status === BNTO_STATUS.PAUSED ||
            topInfo.status === BNTO_STATUS.CANCELLED
          ">
            <img src="@/assets/img/icon-locked.svg" alt="locked">
          </div>
        </template>
        <!-- 非会员 -->
        <div class="lock" v-else>
          <img src="@/assets/img/icon-locked.svg" alt="locked">
        </div>
      </template>
      <!-- 未登录 -->
      <div class="lock" v-else>
        <img src="@/assets/img/icon-locked.svg" alt="locked">
      </div>

      <!-- 已登录 -->
      <template v-if="isLogin">
        <!-- 是会员 -->
        <div class="status" v-if="userInfo.isMember">
          Status:
          <span
            class="label"
            :class="{
              alert: topInfo.status === BNTO_STATUS.UNRESOLVED,
              bordered: topInfo.status === BNTO_STATUS.PAUSED || topInfo.status === BNTO_STATUS.CANCELLED
            }"
          >
            {{ topInfo.status }}
          </span>
          <span class="purchased" v-if="topInfo.purchasedNum">{{topInfo.purchasedNum}} Purchased</span>
        </div>
        <!-- 非会员 -->
        <div class="status" v-else>
          Status: <span class="label bordered">Not Subscribed</span>
        </div>
      </template>
      <!-- 未登录 -->
      <div class="status" v-else>
        Status: <span class="label bordered">Guest</span>
      </div>
    </div>

    <!-- situation 11 取消-->
    <div class="header-bottom" :style="{display: isLogin && userInfo.isMember && topInfo.status === BNTO_STATUS.CANCELLED ? 'none' : 'block'}">
      <!-- 已登录 -->
      <template v-if="isLogin">
        <!-- 是会员 -->
        <template v-if="userInfo.isMember">
          <template v-if="topInfo.status === BNTO_STATUS.OPEN || topInfo.status === BNTO_STATUS.FULL">
            <!-- situation 1 空购物车-->
            <span v-if="cartItemList.length === 0">
              Your BNTO is ready to fill! You can rent up to {{ userInfo.bntoUpperLimit }} items this month.
            </span>

            <!-- situation 2 非空购物车 -->
            <template v-else-if="cartItemList.length > 0 && cartItemList.length < userInfo.bntoUpperLimit">
              <!-- situation 3 存在缺货 -->
              <span class="alert" v-if="cartItemList.find(item => item.skuStatus === SKU_STATUS.OUT_OF_STOCK)">
                Sorry, one or more items have been rented out. Please choose another style.
              </span>

              <span v-else>Add more styles to your BNTO.</span>
            </template>

            <!-- situation 4 购物车已满 -->
            <template v-else-if="cartItemList.length === userInfo.bntoUpperLimit">
              <!-- situation 3 存在缺货 -->
              <span class="alert" v-if="cartItemList.find(item => item.skuStatus === SKU_STATUS.OUT_OF_STOCK)">
                Sorry, one or more items have been rented out. Please choose another style.
              </span>

              <span v-else>Your BNTO is full; Please click continue to confirm shipment.</span>
            </template>
          </template>

          <!-- situation 5 订单已提交-->
          <span v-else-if="topInfo.status === BNTO_STATUS.REQUESTED">
            Delivery tracking: Please check back soon for updates.
          </span>

          <!-- situation 6 已发货或分包发货-->
          <div
            class="space-between"
            v-else-if="topInfo.status === BNTO_STATUS.INCOMING || topInfo.status === BNTO_STATUS.PARTIALLY_INCOMING"
          >
            <span>Your BNTO is on its way. Please allow 24-48 hours for shipping updates.</span>
            <nuxt-link :to="topInfo.deliveryTrackingUrl" class="btn-secondary">track bnto</nuxt-link>
          </div>

          <!-- situation 7 已送达或分包送达-->
          <span
            v-else-if="topInfo.status === BNTO_STATUS.DELIVERED || topInfo.status === BNTO_STATUS.PARTIALLY_DELIVERED">
            We hope you are enjoying your BNTO! You will need to return or purchase your current items to unlock your next BNTO box. When returning, please ensure all 6 items are included except for any you have purchased. Any rented items not included will be considered purchased and charged to your account. Once you drop off your return, please allow additional time for the return to sync to BNTO and for your account to unlock.
          </span>

          <!-- situation 8 退回中 已退还-->
          <div
            class="space-between"
            v-else-if="topInfo.status === BNTO_STATUS.RETURN_IN_TRANSIT || topInfo.status === BNTO_STATUS.RETURNED"
          >
            <span>You need to wait until {{userInfo.nextBillingDate}} to place your next BNTO or you can unlock early</span>
            <nuxt-link :to="PATH_UNLOCK" class="btn-secondary">unlock early</nuxt-link>
          </div>

          <!-- situation 9 退货异常-->
          <span v-else-if="topInfo.status === BNTO_STATUS.UNRESOLVED">
            We received your return, but there was one or more issue.
          </span>

          <!-- situation 10 暂停-->
          <span v-else-if="topInfo.status === BNTO_STATUS.PAUSED">
            Next Billing Date <span class="bold">{{ userInfo.nextBillingDate }}</span>
          </span>
        </template>

        <!-- situation 13 非会员/未订阅 -->
        <span v-else>
          Subscribe to add items to your BNTO.
        </span>
      </template>

      <!-- situation 12 游客未登录 -->
      <span v-else>
        Join or Login to add items to your BNTO!
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-cart-header-mobile {
  padding: 8px 16px;
  background-color: $color-BNTO-beige-light;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .close {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid $color-BNTO-beige-dark;
    position: absolute;
    right: 16px;
    cursor: pointer;
  }

  .header-top {
    display: flex;
    align-items: center;

    .number {
      padding: 12px 10px 0 0;

      /* Headline/small */
      font-family: "Alternate Gothic No3 D";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 133.333% */
      text-transform: uppercase;

      .upper-limit {
        color: $color-gray-400;
      }
    }

    .cube {
      display: flex;
      padding: 12px 10px 0 0;

      img {
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }

    .lock {
      display: flex;
      padding: 12px 10px 0 0;

      img {
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }

    .status {
      padding-top: 14px;
      /* Text xxs/Regular */
      font-family: "TWK Lausanne";
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      .label {
        padding: 3px 6px;
        background-color: white;
        margin-left: 4px;
      }

      .alert {
        background-color: $color-error-100;
        color: $color-BNTO-red-alert;
      }

      .bordered {
        border: 1px solid $color-gray-800;
      }

      .purchased {
        /* Text xxs/Regular */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        margin-left: 4px;
      }
    }
  }

  .header-bottom {
    padding-top: 12px;
    padding-bottom: 2px;
    /* Text xxs/Regular */
    font-family: "TWK Lausanne";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-top: 1px dashed $color-gray-400;

    .alert {
      color: $color-BNTO-red-alert
    }

    .bold {
      /* Text xs/Bold */
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 650;
      line-height: 18px; /* 150% */
      margin-left: 8px;
    }

    .space-between {
      display: flex;
      justify-content: space-between;
      gap: 6px;

      .btn-secondary {
        width: 126px;
        height: 31px;
        min-height: 31px;
        flex-shrink: 0;

        /* Button/small */
        font-family: "Druk Wide Cy";
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px; /* 111.111% */
        text-transform: uppercase;
      }
    }
  }
}

.module-cart-header-desktop {
  padding: 8px 16px;
  background-color: $color-BNTO-beige-light;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .close {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid $color-BNTO-beige-dark;
    position: absolute;
    right: 16px;
    cursor: pointer;
  }

  .header-top {
    display: flex;
    align-items: center;

    .number {
      padding: 12px 10px 0 0;

      /* Headline/small */
      font-family: "Alternate Gothic No3 D";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 133.333% */
      text-transform: uppercase;

      .upper-limit {
        color: $color-gray-400;
      }
    }

    .cube {
      display: flex;
      padding: 12px 10px 0 0;

      img {
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }

    .lock {
      display: flex;
      padding: 12px 10px 0 0;

      img {
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }

    .status {
      padding-top: 14px;
      /* Text xxs/Regular */
      font-family: "TWK Lausanne";
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      .label {
        padding: 3px 6px;
        background-color: white;
        margin-left: 4px;
      }

      .alert {
        background-color: $color-error-100;
        color: $color-BNTO-red-alert;
      }

      .bordered {
        border: 1px solid $color-gray-800;
      }

      .purchased {
        /* Text xxs/Regular */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        margin-left: 4px;
      }
    }
  }

  .header-bottom {
    padding-top: 12px;
    padding-bottom: 2px;
    /* Text xxs/Regular */
    font-family: "TWK Lausanne";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-top: 1px dashed $color-gray-400;

    .alert {
      color: $color-BNTO-red-alert
    }

    .bold {
      /* Text xs/Bold */
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 650;
      line-height: 18px; /* 150% */
      margin-left: 8px;
    }

    .space-between {
      display: flex;
      justify-content: space-between;
      gap: 6px;

      .btn-secondary {
        width: 126px;
        height: 31px;
        min-height: 31px;
        flex-shrink: 0;

        /* Button/small */
        font-family: "Druk Wide Cy";
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px; /* 111.111% */
        text-transform: uppercase;
      }
    }
  }
}
</style>
