<script setup>
import {useBaseStore} from "~/stores/base";
import Drawer from "~/components/Drawer.vue";
import ModulePreviewItem from "~/components/DrawerCartOrderPreview/ModulePreviewItem.vue";
import {useCartStore} from "~/stores/cart";
import {orderCreate, orderPrePayDetail} from "~/api/api.order";
import {config} from "~/utils/config";
import {CARDS} from "~/utils/card";
import {Loading} from "@element-plus/icons-vue";
import ModuleInput from "~/components/ModuleInput.vue";
import {handleStripe} from "~/utils/common";
import {FROM, PATH_PAYMENT_RESULT_CHECK, URL_ACCOUNT_RENTAL_HISTORY} from "~/utils/constants";
import {useSubscribeInfoStore} from "~/stores/subscribe";
import {tiktokPixelTrack} from "~/utils/tiktok-track";
import {shareASalePurchaseSuccessTracking} from "~/utils/tracking";

const BNTOMessage = useNuxtApp().$BNTOMessage
const router = useRouter()

/** emits **/
const emits = defineEmits([])

/** props **/
const props = defineProps({})

/** data **/
const promoCode = ref("")
const promoCodeError = ref(false)
const promoCodeErrorTips = ref("")
const promoCodeValid = ref(false)
const point = ref("")
const pointError = ref(false)
const pointErrorTips = ref("")
const pointValid = ref(false)

const paymentInfo = ref({})
const summary = ref({})
const totalBonus = ref("")

const isLoading = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)
const isShowDrawerCartOrderPreview = computed(() => useBaseStore().getterIsShowDrawerCartOrderPreview)
const cartItemList = computed(() => useCartStore().getterCartItemList)
const shippingAddress = computed(() => useSubscribeInfoStore().getterShippingAddress)

/** watch **/
watch(promoCode, () => {
  promoCodeValid.value = false
})
watch(point, () => {
  pointValid.value = false
})
watch(isShowDrawerCartOrderPreview, (open) => {
  if (open) {
    reset()
    getDetail()
  }
})

/** methods **/
const handleCloseDrawerCartOrderPreview = () => {
  useBaseStore().updateIsShowDrawerCartOrderPreview(false)
}
const reset = () => {
  promoCode.value = ""
  promoCodeError.value = false
  promoCodeErrorTips.value = ""
  promoCodeValid.value = false
  point.value = ""
  pointError.value = false
  pointErrorTips.value = ""
  pointValid.value = false

  paymentInfo.value = {}
  summary.value = {}
  totalBonus.value = ""

  isLoading.value = false
}
const getDetail = async () => {
  try {
    const res = await orderPrePayDetail({
      couponCode: promoCode.value,
      scene: "BNTO",
      point: point.value,
    })
    console.log("orderPrePayDetail", res.result)
    if (promoCode.value && point.value) {
      promoCodeValid.value = true
      pointValid.value = true
    } else if (promoCode.value) {
      promoCodeValid.value = true
    } else if (point.value) {
      pointValid.value = true
    }
    paymentInfo.value = res.result.billingInfo
    useSubscribeInfoStore().updateShippingAddress(res.result.shippingAddress)
    summary.value = res.result.summary
    totalBonus.value = res.result.totalBonus
  } catch (e) {
    console.error(e)
    switch (e.code) {
      case config.BUSINESS_CODE.INVALID_PROMO_CODE:
        promoCodeError.value = true
        promoCodeErrorTips.value = e.message.content
        break;
      case config.BUSINESS_CODE.PROMO_CODE_USED:
        promoCodeError.value = true
        promoCodeErrorTips.value = e.message.content
        break;
      case config.BUSINESS_CODE.PROMO_CODE_EXCLUDED_PRODUCT:
        promoCodeError.value = true
        promoCodeErrorTips.value = e.message.content
        break;
      case config.BUSINESS_CODE.INSUFFICIENT_POINTS:
        pointError.value = true
        pointErrorTips.value = e.message.content
        break;
      case config.BUSINESS_CODE.PAYMENT_RESULT_CHECK:
        const {orderId} = e.result
        const returnUrl = `${URL_ACCOUNT_RENTAL_HISTORY}?orderId=${orderId}&from=${FROM.BNTO_RENT}`
        await router.push(`${PATH_PAYMENT_RESULT_CHECK}?orderId=${orderId}&redirect_uri=${encodeURIComponent(returnUrl)}`)
        break;
    }
  }
}
const handlePromoCodeBlur = () => {
  // reset
  promoCodeValid.value = false
  promoCodeError.value = false
  promoCodeErrorTips.value = ""
}
const handlePromoCodeClearClick = async () => {
  promoCodeValid.value = false
  await getDetail()
}
const handlePromoCodeApplyClick = async () => {
  if (!promoCode.value) return
  await getDetail()
}
const handlePointBlur = () => {
  // reset
  pointValid.value = false
  pointError.value = false
  pointErrorTips.value = ""
}
const handlePointClearClick = async () => {
  pointValid.value = false
  await getDetail()
}
const handlePointApplyClick = async () => {
  if (!point.value) return handlePointClearClick()
  if (!Number.isInteger(Number(point.value))) {
    pointError.value = true
    pointErrorTips.value = "Please enter an integer. You cannot exceed your balance."
    return
  }
  if (Number(point.value) === 0) {
    pointError.value = true
    pointErrorTips.value = "Please enter a non-zero integer"
    return
  }
  if (Number(point.value) < 0 || Number(point.value) > Number(totalBonus.value)) {
    pointError.value = true
    pointErrorTips.value = "Invalid Bonus Credit"
    return
  }
  await getDetail()
}
const handleShipmentEdit = () => {
  useBaseStore().updateIsShowDrawerCartShipmentEdit(true)
}
const handlePurchase = async () => {
  if (isLoading.value || cartItemList.value.find(item => item.skuStatus === SKU_STATUS.OUT_OF_STOCK)) return
  try {
    isLoading.value = true
    // BNTO 租赁
    const res = await orderCreate({
      businessType: "BNTO",
      pointsDeductAmount: point.value,
      promotionCode: promoCode.value,
    })
    isLoading.value = false
    console.log("orderCreate", res.result)

    // 埋点
    const {orderId, payIntentClientSecret, paymentMethodId} = res.result
    tiktokPixelTrack("InitiateCheckout", {content_id: orderId})
    shareASalePurchaseSuccessTracking({orderId, amount: summary.value.retailPrice})

    const redirect_uri = `${URL_ACCOUNT_RENTAL_HISTORY}?orderId=${orderId}&from=${FROM.BNTO_RENT}`
    // success = 1
    await router.push(`${PATH_PAYMENT_RESULT_CHECK}?status=1&redirect_uri=${encodeURIComponent(redirect_uri)}`)
  } catch (e) {
    isLoading.value = false
    const {orderId, payIntentClientSecret, paymentMethodId} = e.result

    // 埋点
    tiktokPixelTrack("InitiateCheckout", {content_id: orderId})
    shareASalePurchaseSuccessTracking({orderId, amount: summary.value.retailPrice})

    const redirect_uri = `${URL_ACCOUNT_RENTAL_HISTORY}?orderId=${orderId}&from=${FROM.BNTO_RENT}`
    if (e.code === config.BUSINESS_CODE.STRIPE_EXECUTE) {
      // 去 Stripe
      await handleStripe(payIntentClientSecret, orderId, redirect_uri)
    } else if (e.code === config.BUSINESS_CODE.PAYMENT_RESULT_CHECK) {
      // 去 支付结果校验页面
      await router.push(`${PATH_PAYMENT_RESULT_CHECK}?orderId=${orderId}&redirect_uri=${encodeURIComponent(redirect_uri)}`)
    }
  }
}

/** lifecycle **/

</script>

<template>
  <div class="drawer-cart-order-preview-mobile" v-if="isMobile">
    <Drawer
      :open="isShowDrawerCartOrderPreview"
      :position="'right'"
      :frosted-glass="false"
      :z-index="3"
      @close="handleCloseDrawerCartOrderPreview"
    >
      <div class="drawer">
        <div class="header">
          <div class="close" @click="handleCloseDrawerCartOrderPreview">
            <img src="@/assets/img/icon-close-black.svg" alt="close">
          </div>

          <div class="header-top">
            review your order
          </div>
          <div class="header-bottom">
            Please review your order details carefully. When you're ready, click 'Confirm Shipment' to place your order.
          </div>
        </div>

        <div class="body">
          <div class="products">
            <module-preview-item v-for="item in cartItemList" :product="item"/>
          </div>
          <div class="payment-info" v-if="summary.premiumNum">
            <div class="title">Payment Details</div>
            <div class="credit-card" v-if="paymentInfo.creditCardNumber">
              <img :src="CARDS[paymentInfo.cardBrand]?.icon" alt="icon-card">
              {{ CARDS[paymentInfo.cardBrand]?.name }} ····{{ paymentInfo.creditCardNumber }}
            </div>
          </div>
          <div class="summary" v-if="summary.premiumNum">
            <div class="title">Summary</div>
            <div class="loading" v-if="!summary.totalBilled">
              <el-icon class="is-loading" size="14">
                <Loading/>
              </el-icon>
            </div>
            <template v-else>
              <div class="summary-body">
                <div class="cell">
                  <span>Retail Price <span class="bold">({{ summary.premiumNum }})</span></span>
                  <span>{{ summary.currency }}{{ summary.retailPrice }}</span>
                </div>
                <div class="cell" v-if="summary.couponDeductAmount">
                  <span>Promo Code</span>
                  <span class="green">(-{{ summary.currency }}{{ summary.couponDeductAmount }})</span>
                </div>
                <div class="cell" v-if="summary.pointsDeductAmount">
                  <span>Bonus Credit Applied</span>
                  <span class="green">(-{{ summary.currency }}{{ summary.pointsDeductAmount }})</span>
                </div>
                <div class="cell" v-if="summary.taxes">
                  <span>Taxes</span>
                  <span>{{ summary.currency }}{{ summary.taxes }}</span>
                </div>
                <div class="cell">
                  <span>Delivery</span>
                  <span>{{
                      summary.shippingPrice === '0.00' ? 'Free' : summary.currency + summary.shippingPrice
                    }}</span>
                </div>
              </div>
              <div class="total">
                <span>Total</span>
                <span>{{ summary.currency }}{{ summary.totalBilled }}</span>
              </div>
            </template>
          </div>
          <div class="tips" v-if="summary.premiumNum">
            You have already paid your monthly subscription fee. Additional charges may apply for premium styles - items
            with high retail value, or those requiring extremely delicate care.
          </div>
          <div class="code-container" v-if="summary.premiumNum">
            <div class="title">
              <img src="@/assets/img/icon-coupon.svg" alt="coupon">
              Promo Code
            </div>
            <div class="input-container">
              <module-input
                type="text"
                v-model="promoCode"
                name="coupon"
                placeholder="Copy your code here"
                :input-class="promoCodeValid ? 'code-valid' : 'code-input'"
                :error-tips="promoCodeErrorTips"
                :error="promoCodeError"
                @blur="handlePromoCodeBlur"
                :clearable="promoCodeValid"
                :suffix-button="!promoCodeValid"
                :suffix-button-text="'apply'"
                @confirm="handlePromoCodeApplyClick"
                @clear="handlePromoCodeClearClick"
              />
            </div>
          </div>
          <div class="code-container" v-if="false">
            <div class="title">
              <img src="@/assets/img/icon-currency-dollar.svg" alt="icon">
              BNTO Bonus Credit balance {{ summary.currency }}{{ totalBonus }}
            </div>
            <div class="input-container">
              <module-input
                type="text"
                v-model="point"
                name="coupon"
                placeholder=""
                :input-class="pointValid ? 'code-valid' : 'code-input'"
                :error-tips="pointErrorTips"
                :error="pointError"
                @blur="handlePointBlur"
                :clearable="pointValid"
                :suffix-button="!pointValid"
                :suffix-button-text="'apply'"
                @confirm="handlePointApplyClick"
                @clear="handlePointClearClick"
              />
            </div>
          </div>
          <div class="shipment">
            <div class="title">
              <span>Ship To</span>
              <div class="btn-text" @click="handleShipmentEdit">edit</div>
            </div>
            <div class="content">
              {{ shippingAddress.firstName }} {{ shippingAddress.lastName }} <br>
              {{ shippingAddress.streetAddress }} <br>
              {{ shippingAddress.details }} <br>
              {{ shippingAddress.city }}, {{ shippingAddress.state }} {{ shippingAddress.zip }} <br>
              {{ shippingAddress.mobile }}
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="btn-secondary" @click="handleCloseDrawerCartOrderPreview"><span>back</span></div>
          <div class="btn-primary" :class="{'btn-disabled': isLoading}" @click="handlePurchase">
            <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
              <Loading/>
            </el-icon>
            <span>confirm shipment</span>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
  <div class="drawer-cart-order-preview-desktop" v-else>
    <Drawer
      :open="isShowDrawerCartOrderPreview"
      :position="'right'"
      :frosted-glass="false"
      :z-index="3"
      @close="handleCloseDrawerCartOrderPreview"
    >
      <div class="drawer">
        <div class="header">
          <div class="close" @click="handleCloseDrawerCartOrderPreview">
            <img src="@/assets/img/icon-close-black.svg" alt="close">
          </div>

          <div class="header-top">
            review your order
          </div>
          <div class="header-bottom">
            Please review your order details carefully. When you're ready, click 'Confirm Shipment' to place your order.
          </div>
        </div>

        <div class="body">
          <div class="products">
            <module-preview-item v-for="item in cartItemList" :product="item"/>
          </div>
          <div class="payment-info" v-if="summary.premiumNum">
            <div class="title">Payment Details</div>
            <div class="credit-card" v-if="paymentInfo.creditCardNumber">
              <img :src="CARDS[paymentInfo.cardBrand]?.icon" alt="icon-card">
              {{ CARDS[paymentInfo.cardBrand]?.name }} ····{{ paymentInfo.creditCardNumber }}
            </div>
          </div>
          <div class="summary" v-if="summary.premiumNum">
            <div class="title">Summary</div>
            <div class="loading" v-if="!summary.totalBilled">
              <el-icon class="is-loading" size="14">
                <Loading/>
              </el-icon>
            </div>
            <template v-else>
              <div class="summary-body">
                <div class="cell">
                  <span>Retail Price <span class="bold">({{ summary.premiumNum }})</span></span>
                  <span>{{ summary.currency }}{{ summary.retailPrice }}</span>
                </div>
                <div class="cell" v-if="summary.couponDeductAmount">
                  <span>Promo Code</span>
                  <span class="green">(-{{ summary.currency }}{{ summary.couponDeductAmount }})</span>
                </div>
                <div class="cell" v-if="summary.pointsDeductAmount">
                  <span>Bonus Credit Applied</span>
                  <span class="green">(-{{ summary.currency }}{{ summary.pointsDeductAmount }})</span>
                </div>
                <div class="cell" v-if="summary.taxes">
                  <span>Taxes</span>
                  <span>{{ summary.currency }}{{ summary.taxes }}</span>
                </div>
                <div class="cell">
                  <span>Delivery</span>
                  <span>{{
                      summary.shippingPrice === '0.00' ? 'Free' : summary.currency + summary.shippingPrice
                    }}</span>
                </div>
              </div>
              <div class="total">
                <span>Total</span>
                <span>{{ summary.currency }}{{ summary.totalBilled }}</span>
              </div>
            </template>
          </div>
          <div class="tips" v-if="summary.premiumNum">
            You have already paid your monthly subscription fee. Additional charges may apply for premium styles - items
            with high retail value, or those requiring extremely delicate care.
          </div>
          <div class="code-container" v-if="summary.premiumNum">
            <div class="title">
              <img src="@/assets/img/icon-coupon.svg" alt="coupon">
              Promo Code
            </div>
            <div class="input-container">
              <module-input
                type="text"
                v-model="promoCode"
                name="coupon"
                placeholder="Copy your code here"
                :input-class="promoCodeValid ? 'code-valid' : 'code-input'"
                :error-tips="promoCodeErrorTips"
                :error="promoCodeError"
                @blur="handlePromoCodeBlur"
                :clearable="promoCodeValid"
                :suffix-button="!promoCodeValid"
                :suffix-button-text="'apply'"
                @confirm="handlePromoCodeApplyClick"
                @clear="handlePromoCodeClearClick"
              />
            </div>
          </div>
          <div class="code-container" v-if="false">
            <div class="title">
              <img src="@/assets/img/icon-currency-dollar.svg" alt="icon">
              BNTO Bonus Credit balance {{ summary.currency }}{{ totalBonus }}
            </div>
            <div class="input-container">
              <module-input
                type="text"
                v-model="point"
                name="coupon"
                placeholder=""
                :input-class="pointValid ? 'code-valid' : 'code-input'"
                :error-tips="pointErrorTips"
                :error="pointError"
                @blur="handlePointBlur"
                :clearable="pointValid"
                :suffix-button="!pointValid"
                :suffix-button-text="'apply'"
                @confirm="handlePointApplyClick"
                @clear="handlePointClearClick"
              />
            </div>
          </div>
          <div class="shipment">
            <div class="title">
              <span>Ship To</span>
              <div class="btn-text" @click="handleShipmentEdit">edit</div>
            </div>
            <div class="content">
              {{ shippingAddress.firstName }} {{ shippingAddress.lastName }} <br>
              {{ shippingAddress.streetAddress }} <br>
              {{ shippingAddress.details }} <br>
              {{ shippingAddress.city }}, {{ shippingAddress.state }} {{ shippingAddress.zip }} <br>
              {{ shippingAddress.mobile }}
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="btn-secondary" @click="handleCloseDrawerCartOrderPreview"><span>back</span></div>
          <div class="btn-primary" :class="{'btn-disabled': isLoading}" @click="handlePurchase">
            <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
              <Loading/>
            </el-icon>
            <span>confirm shipment</span>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.drawer-cart-order-preview-mobile {
  .drawer {
    width: 375px;
    height: calc(100vh - $mobile-header-height);
    overflow-y: auto;
    padding-bottom: $mobile-safari-address-bar-height;
    position: relative;

    display: flex;
    flex-direction: column;

    .header {
      padding: 8px 16px;
      background-color: $color-BNTO-beige-light;
      position: relative;

      display: flex;
      flex-direction: column;
      gap: 8px;

      .close {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid $color-BNTO-beige-dark;
        position: absolute;
        right: 16px;
        cursor: pointer;
      }

      .header-top {
        padding-top: 19px;
        padding-bottom: 7px;

        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
      }

      .header-bottom {
        padding-top: 12px;
        padding-bottom: 2px;

        /* Text xxs/Regular */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        border-top: 1px dashed $color-gray-400;
      }
    }

    .body {
      height: fit-content;
      padding: 16px;
      margin-bottom: 108px;

      .products {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .payment-info {
        // 0.5px border
        box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
        padding: 16px;
        margin-top: 10px;

        .title {
          overflow: hidden;
          color: $color-gray-500;
          text-overflow: ellipsis;
          /* Text sm/Bold */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 650;
          line-height: 20px; /* 142.857% */
          text-align: left;
          padding: 0;
        }

        .credit-card {
          display: flex;
          align-items: center;

          /* Text sm/Bold */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 650;
          line-height: 20px; /* 142.857% */

          margin-top: 4px;

          img {
            display: flex;
            width: 34px;
            height: 24px;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
          }
        }
      }

      .summary {
        // 0.5px border
        box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
        padding: 5px 16px;
        margin-top: 10px;

        .title {
          overflow: hidden;
          color: $color-gray-500;
          text-overflow: ellipsis;
          /* Text sm/Bold */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 650;
          line-height: 20px; /* 142.857% */
          text-align: left;
          padding: 0;
        }

        .summary-body {
          margin-top: 16px;

          .cell {
            display: flex;
            justify-content: space-between;
            align-items: center;

            /* Text xs/Regular */
            font-family: "TWK Lausanne";
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 18px; /* 150% */
            margin-bottom: 8px;

            .bold {
              font-weight: 650;
            }

            .green {
              color: $color-green-700;
            }
          }
        }

        .total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 16px;

          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */
        }

        .loading {
          height: 154px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .tips {
        margin-top: 10px;
        /* Text xxs/Regular */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      .code-container {
        margin-top: 10px;
        padding: 8px;
        // 0.5px border
        box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;

        .title {
          display: flex;
          align-items: center;
          //styleName: Text sm/Bold;
          font-family: "TWK Lausanne";
          font-weight: 650;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          padding: 0;


          img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
        }

        .input-container {
          margin-top: 8px;
          display: flex;
          position: relative;

          .button-apply {
            cursor: pointer;
            flex-shrink: 0;
            width: 58px;
            height: 36px;
            box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;

            /* Button/small */
            font-family: "Druk Wide Cy";
            font-size: 9px;
            font-style: normal;
            font-weight: 500;
            line-height: 10px; /* 111.111% */
            text-transform: uppercase;

            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .shipment {
        // 0.5px border
        box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
        padding: 16px;
        margin-top: 10px;

        .title {
          /* Text sm/Bold */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 650;
          line-height: 20px; /* 142.857% */

          color: $color-gray-500;

          display: flex;
          justify-content: space-between;

          .btn-text {
            padding: 0;
          }
        }

        .content {
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px;

          margin-top: 10px;
        }
      }
    }

    .footer {
      width: 100%;
      padding: 10px 16px 35px 16px;

      position: fixed;
      bottom: 0;

      display: flex;
      gap: 10px;

      border-top: 1px solid $color-gray-300;
      background: white;
      box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.1);

      .btn-secondary {
        width: 90px;
        flex-shrink: 0;
      }

      .btn-primary {
        width: 243px;
        flex-shrink: 0;
      }
    }
  }
}

.drawer-cart-order-preview-desktop {
  .drawer {
    width: 375px;
    height: calc(100vh - $desktop-header-height);
    overflow-y: auto;
    position: relative;

    display: flex;
    flex-direction: column;

    .header {
      padding: 8px 16px;
      background-color: $color-BNTO-beige-light;
      position: relative;

      display: flex;
      flex-direction: column;
      gap: 8px;

      .close {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid $color-BNTO-beige-dark;
        position: absolute;
        right: 16px;
        cursor: pointer;
      }

      .header-top {
        padding-top: 19px;
        padding-bottom: 7px;

        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
      }

      .header-bottom {
        padding-top: 12px;
        padding-bottom: 2px;

        /* Text xxs/Regular */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        border-top: 1px dashed $color-gray-400;
      }
    }

    .body {
      height: fit-content;
      padding: 16px;
      margin-bottom: 108px;

      .products {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .payment-info {
        // 0.5px border
        box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
        padding: 16px;
        margin-top: 10px;

        .title {
          overflow: hidden;
          color: $color-gray-500;
          text-overflow: ellipsis;
          /* Text sm/Bold */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 650;
          line-height: 20px; /* 142.857% */
          text-align: left;
          padding: 0;
        }

        .credit-card {
          display: flex;
          align-items: center;

          /* Text sm/Bold */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 650;
          line-height: 20px; /* 142.857% */

          margin-top: 4px;

          img {
            display: flex;
            width: 34px;
            height: 24px;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
          }
        }
      }

      .summary {
        // 0.5px border
        box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
        padding: 5px 16px;
        margin-top: 10px;

        .title {
          overflow: hidden;
          color: $color-gray-500;
          text-overflow: ellipsis;
          /* Text sm/Bold */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 650;
          line-height: 20px; /* 142.857% */
          text-align: left;
          padding: 0;
        }

        .summary-body {
          margin-top: 16px;

          .cell {
            display: flex;
            justify-content: space-between;
            align-items: center;

            /* Text xs/Regular */
            font-family: "TWK Lausanne";
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 18px; /* 150% */
            margin-bottom: 8px;

            .bold {
              font-weight: 650;
            }

            .green {
              color: $color-green-700;
            }
          }
        }

        .total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 16px;

          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */
        }

        .loading {
          height: 154px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .tips {
        margin-top: 10px;
        /* Text xxs/Regular */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      .code-container {
        margin-top: 10px;
        padding: 8px;
        // 0.5px border
        box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;

        .title {
          display: flex;
          align-items: center;
          //styleName: Text sm/Bold;
          font-family: "TWK Lausanne";
          font-weight: 650;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          padding: 0;


          img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
        }

        .input-container {
          margin-top: 8px;
          display: flex;
          position: relative;

          .button-apply {
            cursor: pointer;
            flex-shrink: 0;
            width: 58px;
            height: 36px;
            box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;

            /* Button/small */
            font-family: "Druk Wide Cy";
            font-size: 9px;
            font-style: normal;
            font-weight: 500;
            line-height: 10px; /* 111.111% */
            text-transform: uppercase;

            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .shipment {
        // 0.5px border
        box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
        padding: 16px;
        margin-top: 10px;

        .title {
          /* Text sm/Bold */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 650;
          line-height: 20px; /* 142.857% */

          color: $color-gray-500;

          display: flex;
          justify-content: space-between;

          .btn-text {
            padding: 0;
          }
        }

        .content {
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px;

          margin-top: 10px;
        }
      }
    }

    .footer {
      width: 100%;
      padding: 10px 16px 35px 16px;

      position: fixed;
      bottom: 0;

      display: flex;
      gap: 10px;

      border-top: 1px solid $color-gray-300;
      background: white;
      box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.1);

      .btn-secondary {
        width: 90px;
        flex-shrink: 0;
      }

      .btn-primary {
        width: 243px;
        flex-shrink: 0;
      }
    }
  }
}
</style>
